html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 50px);
  padding: 0 25px;
  padding-top: 10px;
}

/* Desktop styles */
.desktopContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafafa;
}

.contentStylesDirectDesktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  border: .5px solid #dad9d9;
  padding: 25px;
  background-color: white;
}

/* Small screens */
@media (max-width: 40em) {
  .desktopContainer {
    background-color: white;
    justify-content: space-between;
    align-items: flex-start;
    /* Example color change for small screens */
  }

  .contentStylesDirectDesktop {
    width: 100%;
    border: none;
    display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 50px);
      padding: 0 25px;
      padding-top: 10px;
  }

}
