@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.question-enter-active {
    animation: slideInRight 0.5s forwards;
}

.question-exit-active {
    animation: slideOutLeft 0.5s forwards;
}

.question-enter-active-back {
    animation: slideInLeft 0.5s forwards;
}

.question-exit-active-back {
    animation: slideOutRight 0.5s forwards;
}